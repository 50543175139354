* {
  margin: 0;
  padding: 0;
}

:root {
  --white: #ffffff;
  --black: #000000;
  --charcol: #36454f;
  --licorice: #1b1212;
  --armygreen: #454b1b;
  --mossgreen: #8a9a5b;
  --green: #daf7a6;
  --gunmatel-gray: #818589;
  --being: #f5f5dc;
}

/* navbar */
.navbar {
  background-color: var(--mossgreen);
}
/* change the brand and text color */
.navbar .navbar-brand,
.navbar .navbar-text {
  color: var(--white);
  font-weight: 800;
}
/* change the link color */
.navbar .navbar-nav .nav-link {
  color: var(--licorice);
  font-weight: 500;
}
/* change the color of active or hovered links */
.navbar .nav-item.active .nav-link,
.navbar .nav-item:focus .nav-link,
.navbar .nav-item:hover .nav-link:hover {
  color: var(--white);
}

.navbar-toggler {
  background-image: url(../src/assets/icon/menu.png);
  background-size: 2em;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.nav-link {
  text-decoration: none;
  color: var(--licorice);
}

.nav-link:hover {
  text-decoration: none;
  color: var(--white);
}

.nav-item {
  text-decoration: none;
}

.nav-item .dropdown .dropdown-menu .dropdown-item {
  background-color: var(--mossgreen);
  color: var(--licorice);
}

.nav-item .dropdown .dropdown-menu .dropdown-item .dropdown-item:hover {
  background-color: var(--mossgreen);
  color: var(--white);
}

.active {
  text-decoration: none;
  color: var(--white);
}

#home {
  padding: 4em;
  background-color: var(--mossgreen);
  background-image: url(../src/assets/imgs/powder-png-4.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: soft-light;
}

.main {
  font-size: 30px;
  color: var(--white);
  font-weight: 200;
}

.main-head {
  color: var(--armygreen);
  text-transform: uppercase;
  font-weight: 700;
}

.main-para {
  color: var(--white);
  font-weight: 350;
  margin-bottom: 1em;
}

.main-knw {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: var(--white);
  background-color: var(--armygreen);
  padding: 0.7em;
  border-radius: 20px;
  margin: 0.5em;
  font-weight: 500;
}

.main-knw:hover {
  text-align: center;
  text-decoration: none;
  color: var(--white);
  background-color: var(--armygreen);
  padding: 0.7em;
  border-radius: 20px;
}

.main-cnt {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: var(--armygreen);
  padding: 0.7em;
  border: 1px solid var(--armygreen);
  border-radius: 20px;
  margin: 0.5em;
  font-weight: 500;
}

.main-cnt:hover {
  text-align: center;
  text-decoration: none;
  color: var(--white);
  background-color: var(--armygreen);
  padding: 0.7em;
  border: 1px solid var(--armygreen);
  border-radius: 20px;
}

/* about */
#about {
  background-color: var(--white);
  padding: 2em;
  margin: 1em;
}

.abt-head-para {
  font-size: 1.5em;
  color: var(--licorice);
  font-weight: 600;
}

.abt-head {
  color: var(--armygreen);
  text-transform: uppercase;
  font-weight: 700;
}

.abt-para {
  padding: 3em 1em 3em 1em;
}

.abt-btn {
  margin-top: 0.5em;
  color: var(--white);
  text-decoration: none;
  background-color: var(--armygreen);
  padding: 0.7em;
}

.abt-btn:hover {
  color: var(--white);
  text-decoration: none;
  background-color: var(--armygreen);
  padding: 0.7em;
}

#sector {
  padding: 4em;
  background-color: var(--mossgreen);
  color: var(--white);
  /* background-image: url(../imgs/powder-png-4.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: soft-light; */
}

.sect-tag {
  font-weight: 500;
}

.sect-head {
  color: var(--armygreen);
  text-transform: uppercase;
  font-weight: 700;
}

.sect-para {
  color: var(--white);
  font-weight: 400;
}

.sect-img {
  padding: 3em 1em 2em 1em;
}

/* role */
#role {
  padding: 3em;
  background-color: var(--white);
}

.role-img {
  padding: 3em 1em 2em 1em;
}

.role-tag {
  color: var(--licorice);
}

.role-head {
  color: var(--armygreen);
  text-transform: uppercase;
  font-weight: 700;
}

/* contact */
#contact {
  padding: 3em;
  text-align: center;
  color: var(--licorice);
  background-color: var(--mossgreen);
}

iframe {
  padding: 2em 1em 2em 1em;
  height: 250px;
}

.fa {
  margin-bottom: 1em;
}

.cnt-head {
  color: var(--armygreen);
  font-weight: 700;
  text-transform: uppercase;
}

.cnt-para {
  color: var(--white);
  margin-bottom: 2em;
}

.contact-data {
  border-right: 1px solid var(--armygreen);
  margin-bottom: 2em;
}

/* footer */
#footer {
  background-color: var(--white);
  padding: 1.5em;
  color: var(--gunmatel-gray);
}

.brand {
  color: var(--mossgreen);
  font-weight: 700;
}

.ftr-head {
  color: var(--licorice);
  font-weight: 700;
}

.fa-twitter-square,
.fa-linkedin-square,
.fa-facebook-square,
.fa-instagram {
  color: var(--mossgreen);
}

.social {
  margin: 0.5em;
}

ul {
  list-style: none;
  line-height: 2.5em;
}

/* li Link {
  text-decoration: none;
  color: var(--gunmatel-gray);
  font-weight: 450;
} */

.footerink {
  text-decoration: none;
  color: var(--gunmatel-gray);
  font-weight: 450;
}

.footerink:hover {
  color: var(--mossgreen);
  font-weight: 500;
}

#footerrights {
  padding: 1em;
  background-color: var(--mossgreen);
  color: var(--white);
  text-align: center;
}

/* about-page */
#about-cont {
  padding: 3em;
  text-align: center;
  background-color: var(--mossgreen);
  color: var(--white);
}

.about-cont-head {
  font-size: 45px;
  font-weight: 500;
}

#about-details {
  background-color: var(--mossgreen);
  padding: 2em;
}

.center {
  text-align: center;
  color: var(--licorice);
  padding: 1.5em;
  font-weight: 450;
}

.head {
  color: var(--white);
}

.para {
  color: var(--licorice);
  padding: 2em;
}

#detail-two {
  background-color: var(--mossgreen);
  padding: 2em;
}

#vision {
  background-color: var(--mossgreen);
  padding: 2em;
}

#mission {
  background-color: var(--mossgreen);
  padding: 1em;
  margin-bottom: 1em;
}

.small-head {
  color: var(--white);
}

.small-para {
  color: var(--licorice);
}

/* chemical-css */
#zinc {
  background-color: var(--mossgreen);
  margin: 3em;
}

.left {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 1em;
}

.right {
  padding-top: 4em;
}

#zinc-oxcide {
  padding: 2em;
}

.application {
  text-align: center;
  margin: 2em 0;
  color: var(--white);
}

.card-head {
  color: var(--white);
  font-weight: 700;
}

.card-para {
  color: var(--licorice);
  font-weight: 500;
}

.appheading {
  color: var(--white);
  font-weight: 450;
}

.apppara,
.end-para {
  color: var(--licorice);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* product Detail */
.product-details,
.chemical-details {
  background-color: var(--mossgreen);
  color: var(--being);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.title {
  color: var(--armygreen);
  font-size: 2rem;
  margin-bottom: 15px;
}

.description {
  font-style: italic;
  color: var(--gunmatel-gray);
  margin-bottom: 15px;
}

.img-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.product-img {
  align-items: center;
  margin: auto;
  display: block;
  position: absolute;
}

.subtitle {
  color: var(--armygreen);
  font-size: 1.3rem;
  margin-top: 20px;
}

.subtitle-data {
  color: var(--charcol);
}

/* ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li {
  padding: 3px 0;
  color: var(--charcol);
  font-size: 0.8rem;
  border-bottom: 1px solid var(--gunmatel-gray);
}

ul li:last-child {
  border-bottom: none;
} */
